import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Carousel from 'react-bootstrap/Carousel'
import React, { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import InfiniteCarousel from 'react-leaf-carousel';
//import { NieveComponente } from './components/NieveComponente'

import './App.js';
import './Notes.css';

import image1 from "./imagenes/logosup.png"
import imagef from "./imagenes/IcoFacebook.svg"
import imagei from "./imagenes/IcoInstagram.svg"
import imaget from "./imagenes/IcoTwitter.svg"
import finallogo from "./imagenes/Logofooter.png"


function Notes({ id }){
  const [catalogo, setCatalogo] = useState([]);
  const [adnotesone, setAdnotesone] = useState([]);
  const [adnotestwo, setAdnotestwo] = useState([]);
  const [interesarte, setInteresarte] = useState([]);
  const [date, setDate] = useState("");
  const [adnotestworesponsive,setAdnotestworesponsive]= useState([]);
    const imageClick = () => {
        console.log('Click');
      }

const handleNote = (event) => {
      axios
      .post('https://vivesmeralda.com/api/rest/svc/getContent/', id, {headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },})
      .then((contents) => {
        console.log(contents.data);
        setCatalogo(contents.data);
       var datevisualed = new Date(parseInt(contents.data.createdTime))
       console.log(datevisualed)
       setDate(datevisualed.getDate() + "/"+datevisualed.getMonth() + "/"+datevisualed.getFullYear())
      })
      .catch(function (error) {
      console.log(error);
    });
};
const handleAnuncios = () => {

  axios
    .get('https://vivesmeralda.com/api/rest/v2/web/home?limit=16&sectionId=2&principalLimit=3&location=2')
    .then((contents) => {
      console.log(contents.data);
      setInteresarte(
        <InfiniteCarousel
                    breakpoints={[
                      {
                        breakpoint: 500,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2,
                        },
                      },
                      {
                        breakpoint: 768,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3,
                        },
                      },
                    ]}
                    /*dots={true}
                    showSides={true}*/
                    sidesOpacity={.5}
                    /*sideSize={.1}*/
                    slidesToScroll={1}
                    slidesToShow={4}
                    scrollOnDevice={true} 
                    >

                    {contents.data.contents.slice(3, 8).map((item)=>(
                      <a href={"?p=notes&id=" + item.contentId}  rel="noopener noreferrer">
                      <div key={item.title}>
                        <img
                          alt=""
                          src={item.image}
                        />
                        <p className="date">{item.date}</p>
                        <p className="text-carrousel-locales">{item.title}</p>
                      </div>
                      </a>
                    ))}
                  </InfiniteCarousel>);

      setAdnotesone(contents.data.campaignsSpace9);
      setAdnotestwo(contents.data.campaignsSpace10);
      setAdnotestworesponsive(contents.data.contents.slice(3, 8));


  })
  .catch((err) => {
    console.log(err);
});
};
  useEffect(() => {
    handleNote();
    handleAnuncios();
  }, []);

  
    return (
    <div className="Notes">

        <Container  className="seccion-1">
         {/*<NieveComponente />*/}
        <div id="fixed">
        <Container id="menunotes">
              <Navbar collapseOnSelect expand="lg"  className="justify-content-center" id="image-menu" >
                <Nav className="mr-auto">
                  <Nav.Link href="?h=app">
                    <img src={image1} width={200} alt=""/>
                  </Nav.Link>
                </Nav>
                    <Nav defaultActiveKey="/home" as="ul" expand="lg" className="justify-content-center" id="contmenuN">
                      <Nav.Item as="li">
                        <Nav.Link href="?h=app" className="menu" id="menuizquierda">Inicio</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link href="?v=videos" eventKey="link-2" className="menu" id="videos">Videos</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link href="https://wa.me/5215612818956" eventKey="link-4" className="menu" id="contactom">Contacto</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <div className="icons-redes">
                    <Nav >
                      <Nav.Link>
                        <img src={imagef} width={"30"} alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/satelitinside")}/>
                      </Nav.Link>
                      <Nav.Link>
                        <img src={imagei} width={"30"} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/sateliteinside/")}/>
                      </Nav.Link>
                      <Nav.Link>
                        <img src={imaget} width={"30"} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/satelite_inside")}/>
                      </Nav.Link>
                    </Nav> 
                    </div>
              </Navbar>
            </Container>
            <Container  id="menuresposivenotesN">
            <Navbar collapseOnSelect expand="lg"  className="justify-content-center">
                <Nav className="mr-auto">
                  <Nav.Link>
                    <img src={image1} width={280} alt=""/>
                  </Nav.Link>
                </Nav>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                </Navbar.Collapse>
              </Navbar>
              <Nav defaultActiveKey="/home" as="ul" expand="lg" className="justify-content-center" id="contmenuN">
              <Nav.Item as="li">
                <Nav.Link href="?h=app" className="menuN" id="menuizquierdaN">Inicio</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link href="?v=videos" eventKey="link-2" className="menuN" id="videosN">Videos</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link href="https://wa.me/5215612818956" className="menuN">Contacto</Nav.Link>
              </Nav.Item>
            </Nav>
            </Container>
        </div>
        </Container>
        <Container className="container-texts-notes">
          <Row>
            <Col>
              <div className='note-and-anuncio'>
                <div className='note'>
                <div className="title">
                  <p>{catalogo.title}</p>
                </div>
                <div className="date">
                 {date}
                </div>
                <div className="img">
                  <img
                    className=""
                    src={"https://vivesmeralda.com/static/content/higo/content/" + catalogo.contentId + "/front.jpg"}
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="sub-title">
                  <p>{catalogo.description}</p>
                </div>
                <div className="text" dangerouslySetInnerHTML={{__html: catalogo.content}} width={100}>
                </div>
                </div>
                <div className='anuncio1'>

                <div className="img-anuncio">
                <Carousel fade>
                  {adnotesone.map((item)=>(
                    <Carousel.Item interval={2000}>
                      <a href={item.forward} rel="noopener noreferrer">
                        <img
                          className=""
                          src={item.image}
                          alt=""
                          width="100%"
                        />
                      </a>
                    </Carousel.Item>
                    ))}
                </Carousel>
              </div>                  
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {/*contenedor del segundo anuncio*/}
        <Container className="seccion-anuncio-inf">
        <Row>
          <Col>
            <Carousel fade className="ad-final">
              {adnotestwo.map((item)=>(
                <Carousel.Item interval={2000}>
                  <a href={item.forward} rel="noopener noreferrer">
                    <img
                      className=""
                      src={item.image}
                      alt=""
                      width="100%"
                    />
                  </a>
                </Carousel.Item>
              ))}
            </Carousel>
        <Carousel fade className="ad-final-responsive">
                {adnotestwo.map((item)=>(
                  <Carousel.Item interval={2000}>
                    <a href={item.forward} rel="noopener noreferrer">
                      <img
                        className=""
                        src={item.image}
                        alt=""
                        width="100%"
                      />
                    </a>
                  </Carousel.Item>
                ))}
                </Carousel>
              </Col>
          </Row>
        </Container>
        <Container className="interesarte">
          <Row className="Row-interesante">
            <Col>
              <div className="box-interesanteN">
                <p>PUEDE INTERESARTE</p>
              </div>
            {interesarte}
            </Col>
          </Row>
          <Row className="Row-interesante-responsive">
            <Col className="box-locales-responsive">
              <div className="box-interesanteN">
                <p>PUEDE INTERESARTE</p>
              </div>
            <Container>
              {adnotestworesponsive.map((item)=>(
                <Row className="cont-notes">
                  <Col>
                    <a href={"?p=notes&id=" + item.contentId} rel="noopener noreferrer">
                      <div  className="cont-notes-responsive">
                        <img
                          alt=""
                          src={item.image}
                          width="80%"
                        />
                        <div className="cont-text-notes">
                          <p className="date">{date}</p>
                          <p className="text-carrousel-locales">{item.title}</p>
                        </div>
                      </div>
                    </a>
                  </Col>
                </Row>
              ))}
              </Container>
            </Col>
          </Row>
        </Container>
        <Container className="seccion-final" id="contactonotes">
        <Container>
          <Row>
            <Col>
              <div className="text-privacidad">
                <ul>
                <strong>
                    {/*<li>Quiénes somos</li>*/}
                    <a  href="?a=privacidad"  rel="noopener noreferrer">
                     <li>Aviso de Privacidad</li>
                    </a>
                    {/*<a href="https://vivesmeralda.com/privacidad.pdf" rel="noopener noreferrer">
                      <li>Legales</li>
                    </a>*/}
                  </strong>
                </ul>
              </div>
            </Col>
            <Col>
              <div  className="cont-logo-footer">
                <img src={finallogo} alt=""/>
              </div>
              <div className="text-logo-foot">
                <p><strong>Todos los derechos reservados, ©2025 Sátelite Inside</strong></p>
              </div>
            </Col>
            <Col>
              <div>
                <Nav className="icons-redes-ultimos">
                  <Nav.Link  className="">
                    <img src={imagef} width={30} alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/satelitinside")}/>
                  </Nav.Link>
                  <Nav.Link>
                    <img src={imagei} width={30} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/sateliteinside/")}/>
                  </Nav.Link>
                  <Nav.Link>
                    <img src={imaget} width={30} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/satelite_inside")}/>
                  </Nav.Link>
                </Nav>
              </div>
              <div className="text-contacto-final">
                <p><strong>Contacto</strong></p>
              </div>
            </Col>
          </Row>
        </Container>
        </Container>
        {/*resposive */}
        <Container  className="seccion-final-responsive">
        <Row>
          <Col className="privacidad-responsive">
            <ul>
            <strong>
                    {/*<li>Quiénes somos</li>*/}
                    <a  href="?a=privacidad"  rel="noopener noreferrer">
                     <li>Aviso de Privacidad</li>
                    </a>
                    {/*<a href="https://vivesmeralda.com/privacidad.pdf" rel="noopener noreferrer">
                      <li>Legales</li>
                    </a>*/}
                  </strong>
            </ul>
          </Col>
          <Col>
            <div  className="logo-responsive">
              <img src={finallogo} alt="" width={150}/>
            </div> 
            <div>
                <Nav className="icons-redes-ultimos">
                  <Nav.Link  className="">
                    <img src={imagef} width={32} alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/satelitinside")}/>
                  </Nav.Link>
                  <Nav.Link>
                    <img src={imagei} width={32} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/sateliteinside/")}/>
                  </Nav.Link>
                  <Nav.Link>
                    <img src={imaget} width={32} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/satelite_inside")}/>
                  </Nav.Link>
                </Nav>
              </div>
          </Col>
        </Row>
        <Row>
          <div className="derechos-reservados-resposive">
            <p><strong>Todos los derechos reservados, ©2025 Sátelite Inside</strong></p>
          </div>
        </Row>
      </Container>
    </div>
    );
};
export default Notes;